import React from "react";
import { ProjectBoxLeft } from "../ProjectBox/ProjectBoxLeft";
import { Grid, Hidden } from "@mui/material";
import { BlobLink } from "../BlobLink";

import { ScreenShot } from "./components/ScreenShot";
import { GridContainer } from "./components/GridContainer";
import { GridDescContainer } from "./components/GridDescContainer";

import Gr1 from "../imgs/gr1.mov"
import Gr2 from '../imgs/gr2.mov'

export const GaelicRun = () => {

    console.log(Gr1)

  return (
    <div style={{ paddingTop: "50px", paddingBottom: "200px" }}>
      <ProjectBoxLeft
        desc="A game for practicing the different forms of Gaelic nouns"
        url="https://gaelicrun.netlify.app"
        hidereadmore
      />

      <GridDescContainer rowReverse>
        <Grid item xs={12}>
          <center>
            <span style={{ fontSize: "20px" }}>
              Gaelic Run is a retro-video-game-style tool for people to practice the the different forms of Gaelic nouns.
            </span>
          </center>
        </Grid>

        <Grid item xs={12}>
          It is built using {" "}
          <b>React, Redux Toolit, Material UI and Axios</b>, the data was scraped using a <b>Python</b> script.
          <br />
          <br />
            <span style={{ marginLeft: "10px", marginRight: "10px" }}>
              <center>
                It's <b>live</b>
                <a href="https://gaelicrun.netlify.app/" target="_blank">
                  <BlobLink content="here" width={45} colour="#8bd3dd" />
                </a>
                and on <b>GitHub</b>
                <a
                  href="https://github.com/gordonmaloney/GaelicRun"
                  target="_blank"
                >
                  <BlobLink content="here" width={45} colour="#8bd3dd" />
                </a>
              </center>
            </span>
          
        </Grid>

      </GridDescContainer>

      <GridContainer row first>
        <Grid item xs={12} sm={4}>
          A character is controlled by the user's input - jumping over enemies when an answer is correct, getting hit and losing a life when it's wrong.
        </Grid>
        <Grid item xs={12} sm={8}>
          <ScreenShot video src={Gr1} rotate="2" />
        </Grid>
      </GridContainer>

      <GridContainer rowReverse>
        <Grid item xs={12} sm={6}>
          Users can adjust a range of settings, which are stored both in state through Redux, and in the user's local storage, to keep them between sessions without needing to create an account.
        </Grid>
        <Grid item xs={12} sm={6}>
          <ScreenShot video src={Gr2} rotate="-2" />
        </Grid>
      </GridContainer>

    </div>
  );
};
