import React from "react";
import { ProjectBoxLeft } from "../ProjectBox/ProjectBoxLeft";
import { Grid, Hidden } from "@mui/material";
import { BlobLink } from "../BlobLink";
import { ScreenShot } from "./components/ScreenShot";
import { GridContainer } from "./components/GridContainer";
import { GridDescContainer } from "./components/GridDescContainer";


export const Facle = () => {
  return (

    <div style={{ paddingTop: "50px", paddingBottom: "200px" }}>
              <ProjectBoxLeft
                desc="Facle is a Scottish Gaelic Wordle clone"
                url="https://facle.netlify.app/"
                hidereadmore
                
              />

      <GridDescContainer rowReferse>
        <Grid item xs={12}>
          <center>
            <span style={{ fontSize: "20px" }}>
Facle is a quick and easy Scottish Gaelic spin-off of Wordle, the word guessing game.            </span>
          </center>
        </Grid>

        <Grid item xs={12}>
        Facle has been an enormous success, with <a href="https://twitter.com/search?q=facle%20🟩&src=typed_query&f=live" target="_blank">hundreds of daily users to this day.</a> 
          <br />
          <br />
          The project has even <a href="https://www.bbc.co.uk/naidheachdan/fbh/60021772" target="_blank">been featured on BBC Alba,</a> the Gaelic TV channel.
          <br />
          <br />


          <center>
            It's <b>live</b>
            <a href="http://facle.netlify.app" target="_blank">
              <BlobLink content="here" width={45} colour="#8bd3dd" />
            </a>
            and on <b>GitHub</b>
            <a
              href="https://github.com/gordonmaloney/facle"
              target="_blank"
            >
              <BlobLink content="here" width={45} colour="#8bd3dd" />
            </a>
          </center>{" "}
        </Grid>
      </GridDescContainer>

    </div>
  );
};
