import React from "react";
import { ProjectBoxLeft } from "../ProjectBox/ProjectBoxLeft";
import { Grid, Hidden } from "@mui/material";
import { BlobLink } from "../BlobLink";

import { ScreenShot } from "./components/ScreenShot";
import { GridContainer } from "./components/GridContainer";
import { GridDescContainer } from "./components/GridDescContainer";

import TA1 from "../imgs/TA1.mov";
import TA2 from "../imgs/TA2.mov";
import TA3 from "../imgs/TA3.mov";

export const TenantAct = () => {
  return (
    <div style={{ paddingTop: "50px", paddingBottom: "200px" }}>
      <ProjectBoxLeft
        desc="A suite of tools for digital campaigning and organising"
        url="https://tenantact.netlify.app"
        hidereadmore
      />

      <GridDescContainer rowReverse>
        <Grid item xs={12}>
          <center>
            <span style={{ fontSize: "20px" }}>
              TenantAct is a (growing) suite of tools for members of tenants'
              union Living Rent to campaign and organise.
            </span>
          </center>
        </Grid>

        <Grid item xs={12}>
          The front-end uses{" "}
          <b>React, Redux, Material UI and Axios</b>, the back-end
          uses <b>MongoDB, Express and Node.js</b>. Data is scraped using <b>Python</b>.
          <br />
          <br />
          <span style={{ marginLeft: "10px", marginRight: "10px" }}>
            <center>
              It's <b>live</b>
              <a href="https://tenantact.netlify.app/" target="_blank">
                <BlobLink content="here" width={45} colour="#8bd3dd" />
              </a>
              and on <b>GitHub</b>
              <a
                href="https://github.com/gordonmaloney/lr-tweeter"
                target="_blank"
              >
                <BlobLink content="here" width={45} colour="#8bd3dd" />
              </a>
              and {" "}
              <a
                href="https://github.com/gordonmaloney/stlObjecterFront"
                target="_blank"
              >
                <BlobLink content="here" width={45} colour="#8bd3dd" />
              </a>
            </center>
          </span>
          <br />
        </Grid>
      </GridDescContainer>

      <GridContainer row first>
        <Grid item xs={12} sm={4}>
          Users can create (and edit) template campaigns, and are then given a unique URL and QR code to share the campaign.
        </Grid>
        <Grid item xs={12} sm={8}>
          <ScreenShot video src={TA1} rotate="2" />
        </Grid>
      </GridContainer>
      <GridContainer rowReverse>
        <Grid item xs={12} sm={6}>
          Designed for the unique context of Scottish politics, users can filter targets based on their location.
        </Grid>
        <Grid item xs={12} sm={6}>
          <ScreenShot video src={TA2} rotate="-2" />
        </Grid>
      </GridContainer>

      <GridContainer row>
        <Grid item xs={12} sm={4}>
          Objecting to new Short Term Lets using the formal channels is extremely difficult - so this tool scrapes the data, maps it out, and walks users through submitting strong objections.
        </Grid>
        <Grid item xs={12} sm={8}>
          <ScreenShot video src={TA3} rotate="3" />
        </Grid>
      </GridContainer>
    </div>
  );
};
