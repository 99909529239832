import React from "react";
import { ProjectBoxLeft } from "../ProjectBox/ProjectBoxLeft";
import { Grid, Hidden } from "@mui/material";
import { BlobLink } from "../BlobLink";
import { ScreenShot } from "./components/ScreenShot";
import { GridContainer } from "./components/GridContainer";
import { GridDescContainer } from "./components/GridDescContainer";

import Fbss1 from "../imgs/fbss1.png";
import Fbss2 from "../imgs/fbss2.mov";
import Fbss3 from "../imgs/fbss3.mov";

export const Makespace = () => {
  return (

    <div style={{ paddingTop: "50px", paddingBottom: "200px" }}>
              <ProjectBoxLeft
                desc="Make Space is a tool to stop men dominating meetings"
                url="https://make-space.netlify.app"
                hidereadmore
                
              />

      <GridDescContainer rowReferse>
        <Grid item xs={12}>
          <center>
            <span style={{ fontSize: "20px" }}>
              Make Space is a simple tool to help make meetings more balanced, by allowing facilitators and chairs to easily track if men are dominating the space.
            </span>
          </center>
        </Grid>

        <Grid item xs={12}>
          <center>
            It's <b>live</b>
            <a href="http://make-space.netlify.app" target="_blank">
              <BlobLink content="here" width={45} colour="#8bd3dd" />
            </a>
            and on <b>GitHub</b>
            <a
              href="https://github.com/gordonmaloney/makespace"
              target="_blank"
            >
              <BlobLink content="here" width={45} colour="#8bd3dd" />
            </a>
          </center>{" "}
        </Grid>
      </GridDescContainer>

    </div>
  );
};
