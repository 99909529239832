import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { AnimatedPage } from "./AnimatedPage";
import { Blob } from "./Blob";
import { BlobNavLink } from "./BlobNavLink";
import { BlobLink } from "./BlobLink";
import { Grid } from "@mui/material";

export const ContactMe = () => {
  const [xExit, setxExit] = useState();
  const [yExit, setyExit] = useState(0);

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  function getWindowDimensions() {
    const { innerWidth: width } = window;
    return {
      width,
    };
  }

  useEffect(() => {
    setScreenWidth(getWindowDimensions());

    function handleResize() {
      setScreenWidth(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <AnimatedPage
      animations={{
        initial: { opacity: 0, y: 0, x: 0 },
        animate: { opacity: 1, y: 0, x: 0 },
        exit: { opacity: 0, y: 0, x: xExit },
      }}
    >
      <div
        style={{
          display: "flex",
          paddingTop: "200px",
          justifyContent: "center",
          height: "100vh",
          width: "100vw",
        }}
      >
        <div style={{ width: "70%", maxWidth: "600px", minWidth: "300px" }}>
          <div style={{ float: "left" }}>
            <div
              style={{
                position: "relative",
                width: "350px",
                maxWidth: "80%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                pointerEvents: "none",
              }}
            >
              <Blob colour="#8bd3dd" radius={90} squash={2.1} />

              <Blob colour="#f3d2c1" radius={85} squash={2.2} />

              <Blob colour="#001858" radius={78} squash={2.3} />

              <h1 className="subHeader">Get in touch</h1>
            </div>
          </div>
          <center>
            <p style={{ width: "90%", marginTop: "130px" }}>
              I'd love to chat - you can catch me in these places:
              <br />
              <br />
              <Grid container spacing={6} justifyContent={'center'}
              className="contactContainer">
                <Grid item className="contactItem">
                  <a href="mailto:gordonmaloney@gmail.com" target="_blank">
                    <BlobLink content="e-mail" width={80} colour="#8bd3dd" />
                  </a>
                </Grid>

                <Grid item className="contactItem">
                  <a href="https://ko-fi.com/gordonmaloney" target="_blank">
                    <BlobLink content="ko-fi" width={80} colour="#8bd3dd" />
                  </a>
                </Grid>

                <Grid item className="contactItem">
                  <a target="_blank" href="https://github.com/gordonmaloney">
                    <BlobLink content="github" width={70} colour="#8bd3dd" />
                  </a>
                </Grid>

                <Grid item className="contactItem">
                  <a target="_blank" href="https://twitter.com/istreasatuatha">
                    <BlobLink content="twitter" width={70} colour="#8bd3dd" />
                  </a>
                </Grid>

                <Grid item className="contactItem">
                  <a
                    target="_blank"
                    href="https://www.instagram.com/gordonmaloney/"
                  >
                    <BlobLink content="instagram" width={90} colour="#8bd3dd" />
                  </a>
                </Grid>
              </Grid>
            </p>
          </center>
          <div
            style={{
              zIndex: 6,
              position: "fixed",
              left: screenWidth.width > 900 ? "2%" : "2%",
              top: "10px",
              paddingTop: xExit == 0 || xExit == undefined ? 0 : "100px",
            }}
            onClick={() => setxExit(100)}
          >
            <Link to="../l/about">
              {" "}
              <BlobNavLink text="About me" />
            </Link>
          </div>
          <div
            style={{
              zIndex: 6,
              position: "fixed",
              right: xExit == 0 || xExit == undefined ? "2%" : "2%",
              top: "10px",
              paddingTop: xExit == 0 || xExit == undefined ? 0 : "100px",
            }}
            onClick={() => setxExit(-100)}
          >
            <Link to="../r/portfolio">
              <BlobNavLink text="My work" />
            </Link>
          </div>
        </div>
      </div>
    </AnimatedPage>
  );
};
