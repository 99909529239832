import React from "react";
import { Blob } from "./Blob";

export const BlobLink = ({ content, width, colour }) => {
    return (
      <div
        style={{
          position: "relative",
          width: width,
          display: "inline-flex",
          alignItems: "center",
          justifyContent: "center",
          zIndex: "-1",
          //color: 'white'
        }}
      >
        <Blob colour={colour} radius={95} squash={2.8} />
  
        <span style={{ zIndex: 5 }}>
            <u>{content}</u>
        </span>
      </div>
    );
  };
  