import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { AnimatedPage } from "./AnimatedPage";
import { Blob } from "./Blob";
import { BlobNavLink } from "./BlobNavLink";
import { BlobLink } from "./BlobLink";

export const AboutMe = () => {
  const [xExit, setxExit] = useState();
  const [yExit, setyExit] = useState(0);

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  function getWindowDimensions() {
    const { innerWidth: width } = window;
    return {
      width,
    };
  }

  useEffect(() => {
    setScreenWidth(getWindowDimensions());

    function handleResize() {
      setScreenWidth(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <AnimatedPage
      animations={{
        initial: { opacity: 0, y: 0, x: 0 },
        animate: { opacity: 1, y: 0, x: 0 },
        exit: { opacity: 0, y: 0, x: xExit },
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          height: "100vh",
          width: "100vw",
          paddingTop: "200px",
          overflowX: 'hidden'
        }}
      >
        <div style={{ width: "70%", maxWidth: "700px", minWidth: "280px" }}>
          <div style={{ float: "left" }}>
            <div
              style={{
                position: "relative",
                width: "300px",
                maxWidth: "80%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                pointerEvents: "none",
              }}
            >
              <Blob colour="#8bd3dd" radius={90} squash={2} />

              <Blob colour="#f3d2c1" radius={85} squash={2.1} />

              <Blob colour="#001858" radius={78} squash={2.2} />

              <h1 className="subHeader">About Me</h1>
            </div>
          </div>
          <center>
            <p style={{ width: "90%", marginTop: "100px", paddingBottom: '150px' }}>
              <span style={{ fontSize: "x-large" }}>Hi!</span>
              <br />
              <br />
              My name is <b style={{ fontSize: "x-large" }}>Gordon Maloney</b>,
              and I'm a{" "}
              <b style={{ fontSize: "x-large" }}>full-stack web developer</b>,
              specialising in <b style={{ fontSize: "x-large" }}>React</b>.
              <br /> <br />I have worked for{" "}
              <b style={{ fontSize: "large" }}>more than six years</b> building,
              testing and perfecting user journeys, and for the last few years
              have been doing freelance web developing, having completed the{" "}
              <b style={{ fontSize: "large" }}>NuCamp full-stack bootcamp.</b>
              <br /> <br />I can help you plan, design and build{" "}
              <b style={{ fontSize: "large" }}>
                user-friendly front-ends
              </b> and{" "}
              <b style={{ fontSize: "large" }}>clean, simple back-ends</b>.
              <br /> <br />I am particularly passionate about helping unions,
              not-for-profits and social justice groups{" "}
              <b style={{ fontSize: "large" }}>
                use digital tools to make the world a better place
              </b>
              .
              <br />
              <br />I use <b style={{ fontSize: "large" }}>ReactJS</b>,{" "}
              <b style={{ fontSize: "large" }}>Python</b>,{" "}
              <b style={{ fontSize: "large" }}>CSS</b>,{" "}
              <b style={{ fontSize: "large" }}>MUI</b>,{" "}
              <b style={{ fontSize: "large" }}>Express</b>,{" "}
              <b style={{ fontSize: "large" }}>NodeJS</b>,{" "}
              <b style={{ fontSize: "large" }}>MongoDB</b>,{" "}
              <b style={{ fontSize: "large" }}>Adobe Photoshop</b>, and{" "}
              <b style={{ fontSize: "large" }}>more</b> - but I'm always keen to
              learn something new.
              <br />
              <br />
              <Link to="../r/contact" onClick={() => setxExit(-100)}>
                <BlobLink content="Let's chat!" width={90} colour="#8bd3dd" />
              </Link>
            </p>
          </center>

          <div
            style={{
              zIndex: 6,
              position: "fixed",
              left: screenWidth.width > 900 ? "2%" : "2%",
              top: '10px',
              paddingTop: xExit == 0 || xExit == undefined ? 0 : '100px',
            }}
            onClick={() => setxExit(100)}
          >
            <Link to="../l/portfolio">
              <BlobNavLink text={`My work`} />
            </Link>
          </div>
          <div
            style={{
              zIndex: 6,
              position: "absolute",
              right: xExit == 0 || xExit == undefined ? '2%' : '2%',
              top: '10px',
              paddingTop: xExit == 0 || xExit == undefined ? 0 : '100px',
            }}
            onClick={() => setxExit(-100)}
          >
            <Link to="../r/contact">
              <BlobNavLink text="Get in touch" />
            </Link>
          </div>
          
        </div>
      </div>
    </AnimatedPage>
  );
};
